<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>产品管理</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <a-row v-if="!isBatch" class="button-container">
      <a-col :span="2" :offset="21">
        <a-button type="primary" @click="onBatch">
          批量处理
        </a-button>
      </a-col>
      <a-col :span="1">
        <a-button type="primary" @click="onAdd">
          新增
        </a-button>
      </a-col>
    </a-row>
    <a-row v-else class="button-container-second">
      <a-col :span="4" :offset="20">
        <a-space>
          <a-button type="white" @click="onBatchChange(true)">
            批量启用
          </a-button>
          <a-button type="white" @click="onBatchChange(false)">
            批量停用
          </a-button>
          <a-button type="white" @click="onCancel">取消</a-button>
        </a-space>
      </a-col>
    </a-row>
    <div class="content-container">
      <a-form :form="form" class="advanced-search-form">
        <a-row>
          <a-col :span="5">
            <a-form-item label="产品名称">
              <a-input v-decorator="['productName', { initialValue: '' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="产品编码">
              <a-input v-decorator="['productCode', { initialValue: '' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="产品类型">
              <a-select
                v-decorator="['productType', { initialValue: '' }]"
                :options="typeList"
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="产品状态">
              <a-select
                v-decorator="['productStatus', { initialValue: -1 }]"
                :options="statusList"
              />
            </a-form-item>
          </a-col>
          <a-col :span="2" :offset="1">
            <a-button
              type="primary"
              icon="search"
              class="search-button"
              @click="onSearch"
            >
              搜索
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="main-content">
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          row-key="productId"
          :row-selection="
            isBatch
              ? { selectedRowKeys: selectedRowKeys, onChange: onSelectChange }
              : null
          "
          @change="onPage"
        >
          <div
            slot="productImg"
            slot-scope="text, record"
            class="product-img-container"
          >
            <img
              v-if="record.productImages && record.productImages.length !== 0"
              :src="imageBaseUrl + record.productImages[0].fileUrl"
              alt="产品图片"
            />
            <div v-else></div>
          </div>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">详情</a>
            <a @click="onModify(record)">修改</a>
            <a v-if="record.productStatus === '启用'" @click="onDelete(record)">
              停用
            </a>
            <a v-else @click="onDelete(record)">启用</a>
          </a-space>
        </a-table>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import {
  fetchProductList,
  modifyProductStatus
} from "@/services/ProductService";
import moment from "moment";
import { imageBaseUrl } from "@/services/HttpService";

const tblColumns = [
  {
    title: "产品图",
    key: "productImg",
    scopedSlots: { customRender: "productImg" },
    width: "6%"
  },
  {
    title: "产品名称",
    dataIndex: "productName"
  },
  {
    title: "产品编码",
    dataIndex: "productCode"
  },
  {
    title: "产品类型",
    dataIndex: "productType"
  },
  {
    title: "产品状态",
    dataIndex: "productStatus"
  },

  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "更新时间",
    dataIndex: "modifiedTime"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      tblData: [
        {
          productName: "电动车电摩车把",
          productId: "874689839",
          productType: "配件",
          productStatus: "启用",
          createTime: "2021-03-04",
          modifiedTime: "2021-03-04"
        }
      ],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      typeList: [
        { value: "", label: "全部" },
        { value: "整车", label: "整车" },
        { value: "配件", label: "配件" }
      ],
      statusList: [
        { value: -1, label: "全部" },
        { value: 1, label: "启用" },
        { value: 0, label: "停用" }
      ],
      selectedRowKeys: [],
      isBatch: false,
      imageBaseUrl
    };
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    onBatchChange(status) {
      if (this.selectedRowKeys.length === 0) {
        this.$message.error("请至少选择一个产品");
        return;
      }
      const param = {
        enableFlag: status,
        productIds: this.selectedRowKeys
      };
      modifyProductStatus(param)
        .then(resp => {
          if (resp.data.code === "SUCCESS") {
            this.$message.success("操作成功");
            this.selectedRowKeys = [];
            this.onSearch();
          }
        })
        .catch(error => {
          if (error) {
            console.log(error);
          }
        });
    },
    onSearch() {
      const params = {
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form.getFieldsValue()
      };

      this.fetchList(params);
    },
    onLoad() {
      const params = {
        pageNum: 0,
        pageSize: 15,
        productCode: "",
        productName: "",
        productStatus: "",
        productType: ""
      };
      this.fetchList(params);
    },
    fetchList() {
      const params = {
        pageNum: 0,
        pageSize: 15,
        productCode: "",
        productName: "",
        productStatus: "",
        productType: ""
      };
      this.loading = true;
      fetchProductList(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            this.tblData.forEach(item => {
              if (item.enableFlag) item.productStatus = "启用";
              else item.productStatus = "停用";
              item.createTime = moment(item.createTime).format(
                "YYYY-MM-DD HH:MM:SS"
              );
              item.modifiedTime = moment(item.modifiedTime).format(
                "YYYY-MM-DD HH:MM:SS"
              );
            });
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(error => {
          if (error) {
            this.loading = false;
          }
        });
    },
    onDetail(record) {
      sessionStorage.setItem("productId", record.productId);
      this.$router.push({ name: "ProductAdd", params: { type: "detail" } });
    },
    onModify(record) {
      sessionStorage.setItem("productId", record.productId);
      this.$router.push({ name: "ProductAdd", params: { type: "modify" } });
    },
    onDelete(record) {
      this.selectedRowKeys.push(record.productId);
      this.onBatchChange(!record.enableFlag);
    },
    onAdd() {
      this.$router.push({ name: "ProductAdd", params: { type: "add" } });
    },
    onPage(pagination) {
      this.pagination.current = pagination.current;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onBatch() {
      this.isBatch = true;
    },
    onCancel() {
      this.isBatch = false;
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.button-container {
  margin-right: 26px;
  margin-top: 20px;
}
.button-container-second {
  margin-top: 20px;
  margin-right: 50px;
}
.search-button {
  transform: translateY(4px);
}
.button-end {
  margin-left: 40px;
}
.product-img-container {
  width: 100%;
}
.product-img-container img {
  width: 100%;
  object-fit: contain;
}
</style>
