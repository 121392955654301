<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item style="cursor: pointer">
          <span @click="onBack">
            产品管理
          </span>
        </a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'add'">新增产品</a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'detail'">产品详情</a-breadcrumb-item>
        <a-breadcrumb-item v-if="type === 'modify'">修改信息</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div class="content-container">
      <a-row class="form-container">
        <div class="content-main">
          <a-form :form="form" class="advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="产品类型">
                  <div v-if="type === 'detail'">
                    {{ productType }}
                  </div>
                  <a-radio-group
                    v-else
                    :value="productType"
                    :options="typeOptions"
                    :default-value="typeOptions[0]"
                    @change="onChange"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="产品编码">
                  <a-input
                    v-decorator="[
                      'productCode',
                      { rules: [{ required: true, message: '请输入产品编码' }] }
                    ]"
                    :disabled="type === 'detail'"
                    placeholder="请输入产品编码"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="产品名称">
                  <a-input
                    v-decorator="[
                      'productName',
                      { rules: [{ required: true, message: '请输入产品名称' }] }
                    ]"
                    :disabled="type === 'detail'"
                    placeholder="请输入产品名称"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="产品重量">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'productWeight',
                        {
                          rules: [{ required: true, message: '请输入产品重量' }]
                        }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入产品重量"
                    />
                    <div>kg</div>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="产品体积">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'productVolume',
                        {
                          rules: [{ required: true, message: '请输入产品体积' }]
                        }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入产品体积"
                    />
                    <div>m³</div>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="装运体积">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'packVolume',
                        {
                          rules: [{ required: true, message: '请输入装运体积' }]
                        }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入装运体积"
                    />
                    <div>m³</div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-if="productType === '整车'">
              <a-col :span="8">
                <a-form-item label="车系列">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'series',
                        { rules: [{ required: true, message: '请输入车系列' }] }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入车系列"
                    />
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="车颜色">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'color',
                        { rules: [{ required: true, message: '请输入车颜色' }] }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入车颜色"
                    />
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="车配置">
                  <div class="input-container">
                    <a-textarea
                      v-decorator="[
                        'specification',
                        { rules: [{ required: true, message: '请输入车配置' }] }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入车配置"
                    />
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row v-else>
              <a-col :span="8">
                <a-form-item label="配件部位">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'position',
                        {
                          rules: [{ required: true, message: '请输入配件部位' }]
                        }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入配件部位"
                    />
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item label="配件功能">
                  <div class="input-container">
                    <a-input
                      v-decorator="[
                        'functionality',
                        {
                          rules: [{ required: true, message: '请输入配件功能' }]
                        }
                      ]"
                      :disabled="type === 'detail'"
                      placeholder="请输入配件功能"
                    />
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-form-item label="产品介绍">
                  <a-textarea
                    v-decorator="[
                      'productDescription',
                      { rules: [{ required: true, message: '请输入产品介绍' }] }
                    ]"
                    :disabled="type === 'detail'"
                    placeholder="请输入产品介绍"
                  />
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item label="产品图片">
              <a-upload
                name="file"
                list-type="picture-card"
                :file-list="productImgs"
                :show-upload-list="true"
                :action="imgUrl"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChangeProduct"
              >
                <div v-if="productImgs.length < 1 && type !== 'detail'">
                  <a-icon :type="loadingProductImg ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>

            <a-form-item label="展示图">
              <a-upload
                name="file"
                list-type="picture-card"
                :show-upload-list="true"
                :action="imgUrl"
                :before-upload="beforeUpload"
                :file-list="exhibitionImgs"
                @preview="handlePreview"
                @change="handleChangeExhibition"
              >
                <div v-if="exhibitionImgs.length < 8 && type !== 'detail'">
                  <a-icon :type="loadingExhibition ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>

            <a-form-item label="其他展示照片或者渲染图">
              <a-upload
                name="file"
                list-type="picture-card"
                :show-upload-list="true"
                :action="imgUrl"
                :before-upload="beforeUpload"
                :file-list="otherImgs"
                @preview="handlePreview"
                @change="handleChangeOther"
              >
                <div v-if="otherImgs.length < 8 && type !== 'detail'">
                  <a-icon :type="loadingOther ? 'loading' : 'plus'" />
                </div>
              </a-upload>
            </a-form-item>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form>
        </div>
      </a-row>
    </div>
    <div class="examine-container">
      <div class="button-container">
        <a-button
          v-if="type === 'add'"
          type="white"
          class="button-item"
          @click="onFinish('add')"
        >
          完成
        </a-button>
        <a-button
          v-if="type === 'detail'"
          type="white"
          class="button-item"
          @click="onDetail"
        >
          修改
        </a-button>
        <a-button
          v-if="type === 'modify'"
          type="white"
          class="button-item"
          @click="onBack"
        >
          取消
        </a-button>
        <a-button
          v-if="type === 'modify'"
          type="white"
          class="button-item"
          @click="onFinish('modify')"
        >
          完成
        </a-button>
      </div>
    </div>
  </a-layout-content>
</template>

<script>
import { imgUrl } from "@/services/UploadService";
import {
  addProduct,
  fetchProductDetail,
  modifyProductDetail
} from "@/services/ProductService";
import { imageBaseUrl } from "@/services/HttpService";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      typeOptions: ["整车", "配件"],
      productType: "整车",
      loading: false,
      imageUrl: "",
      type: this.$route.params.type,
      imgUrl,
      productImages: [],
      exhibitionImages: [],
      otherImages: [],
      productImgs: [],
      exhibitionImgs: [],
      otherImgs: [],
      loadingProductImg: false,
      loadingExhibition: false,
      loadingOther: false,
      previewVisible: false,
      previewImage: "",
      infoDetail: {}
    };
  },
  mounted() {
    this.onLoadDetail();
  },
  methods: {
    onLoadDetail() {
      if (this.type !== "add") {
        const productId = sessionStorage.getItem("productId");
        fetchProductDetail(productId).then(resp => {
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.infoDetail = data;
            this.form.setFieldsValue({
              productCode: data.productCode,
              productName: data.productName,
              productDescription: data.productDescription,
              packVolume: data.packVolume,
              productVolume: data.productVolume,
              productWeight: data.productWeight
            });
            if (data.productType === "整车") {
              this.form.setFieldsValue({
                series: data.series,
                color: data.color,
                specification: data.specification
              });
            } else {
              this.form.setFieldsValue({
                functionality: data.functionality,
                position: data.position
              });
            }
            this.productType = data.productType;
            this.productImages = data.productImages;
            this.exhibitionImages = data.exhibitionImages;
            this.otherImages = data.otherImages;
            this.productImgs = [];
            this.exhibitionImgs = [];
            this.otherImgs = [];
            this.productImages.forEach((item, index) => {
              this.productImgs.push({
                uid: index + 1,
                name: item.fileName,
                url: imageBaseUrl + item.fileUrl
              });
            });
            this.exhibitionImages.forEach((item, index) => {
              this.exhibitionImgs.push({
                uid: index + 1,
                name: item.fileName,
                url: imageBaseUrl + item.fileUrl
              });
            });
            this.otherImages.forEach((item, index) => {
              this.otherImgs.push({
                uid: index + 1,
                name: item.fileName,
                url: imageBaseUrl + item.fileUrl
              });
            });
          }
        });
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    onChange(e) {
      this.productType = e.target.value;
    },
    onBack() {
      this.$router.push({ name: "ProductManage" });
    },
    onDetail() {
      this.type = "modify";
      this.onLoadDetail();
    },
    handleChangeProduct({ fileList }) {
      this.productImgs = fileList;
    },
    handleChangeExhibition({ fileList }) {
      this.exhibitionImgs = fileList;
    },
    handleChangeOther({ fileList }) {
      this.otherImgs = fileList;
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("请上传图片文件");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片大小不超过2MB");
      }
      return isJpgOrPng && isLt2M;
    },
    onFinish(type) {
      if (this.productImgs.length === 0) {
        this.$message.error("请上传至少一张产品图片");
        return;
      }
      if (this.exhibitionImgs.length === 0) {
        this.$message.error("请上传至少一张展示图");
        return;
      }
      if (this.otherImgs.length === 0) {
        this.$message.error("请上传至少一张其他图片");
        return;
      }
      this.productImgs.forEach(item => {
        if (item.response) {
          this.productImages.push({
            fileName: item.name,
            fileUrl: item.response.data
          });
          item.response = "";
        }
      });
      this.exhibitionImgs.forEach(item => {
        if (item.response) {
          this.exhibitionImages.push({
            fileName: item.name,
            fileUrl: item.response.data
          });
        }
      });
      this.otherImgs.forEach(item => {
        if (item.response) {
          this.otherImages.push({
            fileName: item.name,
            fileUrl: item.response.data
          });
        }
      });
      this.form.validateFields((err, values) => {
        const params = {
          productType: this.productType,
          exhibitionImages: this.exhibitionImages,
          productImages: this.productImages,
          otherImages: this.otherImages,
          ...values
        };
        if (type === "add") {
          addProduct(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("创建成功");
              this.productImages = [];
              this.exhibitionImages = [];
              this.otherImages = [];
              this.onBack();
            } else {
              this.$message.error(resp.data.errorMsg);
            }
          });
        } else {
          params.productId = sessionStorage.getItem("productId");
          modifyProductDetail(params).then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.$message.success("修改成功");
              this.type = "detail";
              this.productImages = [];
              this.exhibitionImages = [];
              this.otherImages = [];
              this.onLoadDetail();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.content-main {
  width: 98%;
}
.input-container {
  display: flex;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.examine-container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}
.button-container {
  width: 12%;
  display: flex;
  justify-content: space-around;
}
/deep/.ant-btn {
  border-radius: 5px;
}
.button-item {
  margin-right: 15px;
}
/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 168px !important;
}
</style>
